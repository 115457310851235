import * as Yup from 'yup';

export const incorrectDipValueMsg = 'Dip After (A) should be greater than Dip Before (B).';
export const invalidCapacityNoMsg = 'Capacity can only contain up to 7 numeric digits. Try again.';
export const invalidDipBeforeMsg = 'Dip Before can only contain up to 7 numeric digits. Try again.';
export const invalidDipAfterMsg = 'Dip After can only contain up to 7 numeric digits. Try again.';
export const invalidActualDischargeQtyMsg = 'Actual Discharge Qty can only contain up to 7 numeric digits. Try again.';
export const invalidQuantityAmbientMsg = 'Quantity Ambient can only contain up to 7 numeric digits. Try again.';
export const invalidQuantityStandardMsg = 'Quantity Standard can only contain up to 7 numeric digits. Try again.';

export const quantityWarningMsg = 'Quantities don’t match. Check again or continue if you are ok with this.';
export const ambientWarningMsg = 'Discharge quantity cannot be greater than remaining quantity ambient ';
export const compartmentWarningMsg = 'Compartments can only contain up to 15 numeric digits. Try again.';

export const initialValues = {
  Tank: '',
  Product: '',
  Capacity: '',
  Compartments: '',
  DipBefore: '',
  DipAfter: '',
  Calculated: '',
  ActualDischargeQty: '',
  QuantityAmbient: '',
  QuantityStandard: '',
  TASVehicleID: '',
  OpenOrder: false,
  Aggregate: false,
};

export const DropDetailsSchema = Yup.object().shape({
  // Required fields
  LoadIdAddon: Yup.string().label('Load ID Addon').required(),
  Product: Yup.string().label('Product').required(),
  // Dip Before Field Can be 0
  DipBefore: Yup.string()
    .label('Dip Before (B)')
    .matches(/^[0-9]*$/, 'Only whole numbers are accepted for this field.')
    .max(7, invalidDipBeforeMsg)
    .required(),
  // Actual Discharge Qty will always be greater than 0 as
  // Dip After is always greater than Dip Before
  ActualDischargeQty: Yup.number()
    .label('Actual Discharge Quantity')
    .moreThan(0, 'Should be a positive number')
    .max(7, invalidActualDischargeQtyMsg)
    .required()
    .when('QuantityAmbient', (qtyAmbient) => {
      if (!qtyAmbient && qtyAmbient !== 0) {
        return Yup.number()
          .label('Actual Discharge Quantity')
          .moreThan(0, 'Should be a positive number')
          .required();
      }
      return Yup.number()
        .label('Actual Discharge Quantity')
        .moreThan(0, 'Should be a positive number')
        .max(qtyAmbient, `Discharge quantity cannot be greater than the quantity ambient (${qtyAmbient}).`)
        .required();
    }),
  Calculated: Yup.number().moreThan(-1, 'Should be 0 or a positive number').label('Calculated (A - B)').required(),
  // Dip After Field will always be a positive number
  DipAfter: Yup.number()
    .moreThan(0, 'Should be a positive number')
    .label('Dip After (A)')
    .max(9999999, invalidDipAfterMsg)
    .when('DipBefore', (dipBefore) => {
      if (!dipBefore && dipBefore !== 0) {
        return Yup.number()
          .moreThan(0, 'Should be a positive number')
          .required()
          .label('Dip After (A)')
          .max(9999999, invalidDipAfterMsg);
      }
      return Yup.number()
        .moreThan(dipBefore, 'This has to be greater than the Dip Before (B) value.')
        .required()
        .label('Dip After (A)')
        .max(9999999, invalidDipAfterMsg);
    }),
  // Capacity should be any numeric values greater than 0
  Capacity: Yup.string()
    .label('Capacity (SFL)')
    .matches(/^[0-9]*$/, 'Only whole numbers are accepted for this field.')
    .max(7, invalidCapacityNoMsg)
    .test(
      '0 is not a valid input',
      'Should be a positive number',
      (value) => value !== '0',
    )
    .required(),

  // Optional
  // Conditionally required fields
  QuantityAmbient: Yup.string()
    .label('Quantity Ambient')
    .when('OpenOrder', {
      is: true,
      then: Yup.string()
        .label('Quantity Ambient')
        .matches(/^[0-9]{1,7}$/, invalidQuantityAmbientMsg)
        .max(9999999, invalidQuantityAmbientMsg) // allow max 7 digits
        .required(),
    }),
  QuantityStandard: Yup.string()
    .label('Quantity Standard')
    .when('OpenOrder', {
      is: true,
      then: Yup.string()
        .label('Quantity Standard')
        .matches(/^[0-9]{1,7}$/, invalidQuantityStandardMsg)
        .max(9999999, invalidQuantityStandardMsg) // allow max 7 digits
        .required(),
    }),
  // Optional fields
  //increased the max size from 15 to 20 for TASK000010623641 by najpbr
  Tank: Yup.string().label('Tank Number / Name'),
  Compartments: Yup.string()
    .max(20, compartmentWarningMsg)
    .matches(/^[0-9-,]+$/, compartmentWarningMsg)
    .label('Compartment(s)'),
  TASVehicleID: Yup.string().label('TAS Vehicle ID'),
});
